import { useLayoutEffect, useRef } from 'react'

export const useLayoutScroll = (key, domId) => {
    const nodeRef = useRef<HTMLElement | null>()
    useLayoutEffect(() => {
        let keyStr = `scrollTop_${key}`
        const dom = (typeof domId == "string") ? document.getElementById(domId) : domId()
        // const dom = document.getElementById(domId)
        nodeRef.current = dom
        const scroll = (e) => {
            localStorage.setItem(keyStr, e.target.scrollTop)
        }
        if (dom) {
            let scrollTop = localStorage.getItem(keyStr) || 0
            dom.scrollTop = Number(scrollTop)
            dom.addEventListener("scroll", scroll)
        }
        return () => {
            dom && dom.removeEventListener("scroll", scroll)
        }
    }, [key])
    const restScrollTop = (top = 0) => {
        if (nodeRef.current) {
            nodeRef.current.scrollTop = Number(top)
        }
    }
    return restScrollTop
}

export default useLayoutScroll
import vercelFetch from '@/utlis/vercelFetch'
import netfetch from '@/utlis/fetch'

export const getUserInfo: any = () => vercelFetch("/mongoose/userinfo")

// 登录
export const login: any = (data) => netfetch.post("/mongoose/login", data)

// 注册
export const register: any = (data) => netfetch.post("/mongoose/register", data)

export const getBooks: any = (data) => netfetch.get(`/books`, data)

export const getBookDetail: any = (data) => netfetch.get(`/books/detail`, data)

export const getBookList: any = (data) => netfetch.get(`/books/list`, data)

export const getBookListComplete: any = (data) => netfetch.get(`/books/list/complete`, data)

export const getBookContent: any = (data) => netfetch.get(`/books/content`, data)
export const getBookContentPrev: any = (data) => netfetch.get(`/books/prev`, data)
export const getBookContentnext: any = (data) => netfetch.get(`/books/next`, data)

export const getStatistics: any = (data) => netfetch.get(`/books/statistics`, data)

export const postBooksUpdate: any = (data) => netfetch.post(`/mongoose/books/update`, data)

// 反馈
export const postFeedBackAdd: any = (data) => netfetch.post(`/mongoose/feedback/add`, data)
import axios from "axios";

const { baseUrl } = window.AppConfig
const netfetch = axios.create({
    baseURL: baseUrl,
    timeout: 15000,
    headers: {
        "Content-Type": "application/json"
    }
});

// 添加请求拦截器
netfetch.interceptors.request.use((config) => {
    config.headers = {
        token: localStorage.token,
        ...config.headers
    };
    return config;
}, (error) => {
    console.log("before", error)
    throw error
});

// 添加响应拦截器
netfetch.interceptors.response.use((response) => {
    if (response.data.success) {
        return response.data.data;
    }
    console.log(response.data);
    throw response.data.message
}, (error) => {
    console.log(error)
    throw error
});

export default netfetch;
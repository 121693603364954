(function webpackUniversalModuleDefinition(root, factory) {
	if(typeof exports === 'object' && typeof module === 'object')
		module.exports = factory(require("react"), require("react-dom"), require("react-router-dom"), require("seamless-immutable"), require("rxjs"), require("rxjs/operators"), require("axios"), require("dexie"));
	else if(typeof define === 'function' && define.amd)
		define("app", ["react", "react-dom", "react-router-dom", "seamless-immutable", "rxjs", "rxjs/operators", "axios", "dexie"], factory);
	else if(typeof exports === 'object')
		exports["app"] = factory(require("react"), require("react-dom"), require("react-router-dom"), require("seamless-immutable"), require("rxjs"), require("rxjs/operators"), require("axios"), require("dexie"));
	else
		root["app"] = factory(root["react"], root["react-dom"], root["react-router-dom"], root["seamless-immutable"], root["rxjs"], root["rxjs/operators"], root["axios"], root["dexie"]);
})(self, (__WEBPACK_EXTERNAL_MODULE__8156__, __WEBPACK_EXTERNAL_MODULE__7111__, __WEBPACK_EXTERNAL_MODULE__6128__, __WEBPACK_EXTERNAL_MODULE__5406__, __WEBPACK_EXTERNAL_MODULE__3832__, __WEBPACK_EXTERNAL_MODULE__3597__, __WEBPACK_EXTERNAL_MODULE__3300__, __WEBPACK_EXTERNAL_MODULE__1025__) => {
return 
import React, { useEffect } from 'react'
import { BrowserRouter } from 'react-router-dom';
import { QueryClient, QueryClientProvider } from "react-query";
import useLocaleContext from '@/useLocaleAppContext'
import { getUserInfo } from "@/servers";
import Router from "./Router";
import 'react-virtualized/styles.css';
import './styles.less'

const queryClient = new QueryClient({
    defaultOptions: {
        queries: {
            cacheTime: 1000 * 60 * 60, // 数据缓存时间 默认 1000 * 60 * 5 5分钟
            staleTime: 1000 * 15, // 重新获取数据的时间间隔 默认0
            retry: 2, // 失败重试次数 默认 3次
            refetchOnWindowFocus: false, // 窗口获得焦点时重新获取数据
            // keepPreviousData: true,
            //   retryDelay: attemptIndex => Math.min(1000 * 2 ** attemptIndex, 30000),
        },
    },
});

const Main = () => {
    const { context, dispatch } = useLocaleContext.getContext()
    const { token, theme } = context
    useEffect(() => {
        if (!token) {
            return
        }
        getUserInfo().then(res => {
            if (res.success) {
                dispatch({ user: res.data, loading: false })
            } else {
                localStorage.removeItem("token")
                dispatch({ token: undefined, loading: false })
            }
        })
    }, [token])
    useEffect(() => {
        localStorage.theme = theme
        let meta = document.querySelector('meta[name="theme-color"]')
        if (meta) {
            meta.setAttribute("content", theme)
        }
        document.documentElement.style.setProperty("--adm-color-primary", theme)
        // document.documentElement.setAttribute(
        //     'data-prefers-color-scheme',
        //     'dark'
        // )
        // const styles = `
        //     body{color:${theme}}
        // `
        // let style = document.createElement("style")
        // style.appendChild(document.createTextNode(styles))
        // document.body.appendChild(style)
        // return () => {
        //     document.body.removeChild(style)
        // }
    }, [theme])
    return <Router />
}

const App = () => {
    const initValue = { token: localStorage.token, loading: true, refreshToken: 0, theme: localStorage.theme || "#00d39a" }
    return <useLocaleContext.Provider value={initValue}>
        <QueryClientProvider client={queryClient}>
            <BrowserRouter>
                <Main />
            </BrowserRouter>
        </QueryClientProvider>
    </useLocaleContext.Provider>
}

export default App
import React, { createContext, useContext } from "react";
import useData from './useData'

export const useCtx = () => {
    const context = createContext<any>({});
    const getContext = () => useContext(context)
    const Provider = ({ value = {}, children }) => {
        const [ctx, dispatch] = useData(value)
        let contextData = { context: ctx, dispatch };
        return <context.Provider value={contextData}>{children}</context.Provider>
    }
    return { getContext, Provider };
}

export default useCtx
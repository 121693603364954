import { useInfiniteQuery } from "react-query";

export const usePageQuery = (key, fetch, query = <any>{}) => {
    const { pageNo = 1, pageSize = 100, ...rest } = query
    const fetchlist = ({ pageParam = pageNo }) => {
        return fetch({ params: { pageSize, ...rest, pageNo: pageParam } })
    }
    const res = useInfiniteQuery(key,
        fetchlist,
        {
            getNextPageParam: (lastPage) => {
                if (!lastPage) {
                    return undefined
                }
                const hasNext = lastPage.list.length == pageSize
                if (hasNext) {
                    return lastPage.pageNo + 1
                }
                return undefined
            }
        })
    const { pages = [] } = res.data || {}
    const last = pages.length == 0 ? {} : pages[pages.length - 1]
    const { total = 0, list: lastList = [] } = last
    const hasNext = lastList.length == pageSize
    const totalPage = Math.ceil(Number(total) / Number(pageSize))
    const list = pages.reduce((arr, item) => {
        return arr.concat(item.list)
    }, [])

    return { ...res, list, totalPage, total, pageSize, hasNext }
}
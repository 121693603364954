import React from 'react'
import { Switch, Route, Redirect } from 'react-router-dom';
import importCompont from '@/utlis/importCompont';

const Home = importCompont(() => import("./Home"))
const Books = importCompont(() => import("./Books"))
const Book = importCompont(() => import("./Books/Book"))
const Content = importCompont(() => import("./Books/Content"))
const Login = importCompont(() => import("./Login"))
const Me = importCompont(() => import("./Me"))
const Tasks = importCompont(() => import("./Tasks"))
const Crawler = importCompont(() => import("./Crawler"))
const Test = importCompont(() => import("./Test"))

const Router = () => {
    return <Switch>
        <Route path="/" exact component={Home} />
        <Route path="/books" exact component={Books} />
        <Route path="/books/:bookId" exact component={Book} />
        <Route path="/books/:bookId/:id" exact component={Content} />
        <Route path="/login" exact component={Login} />
        <Route path="/me" exact component={Me} />
        <Route path="/tasks" exact component={Tasks} />
        <Route path="/crawler" exact component={Crawler} />
        <Route path="/test" exact component={Test} />
        {/* @ts-ignore */}
        <Redirect path="*" replace to="/" />
    </Switch>
}

export default Router
import { useMemo } from 'react'
import { useHistory, useLocation } from 'react-router-dom';
import qs from 'qs'

export const useRouteParams = () => {
    const history = useHistory()
    const { search } = useLocation()
    const params = useMemo(() => {
        if (!search) {
            return {}
        }
        return qs.parse(search.split("?")[1])
    }, [search])
    const setParams = (query = {}, force = false): any => {
        let cquery = force ? query : { ...params, ...query }
        history.replace({ pathname: history.location.pathname, search: qs.stringify(cquery) })
    }
    return [params, setParams] as const
}
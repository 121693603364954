import axios from "axios";

const { baseUrl } = window.AppConfig
const netfetch = axios.create({
    //@ts-ignore
    // baseURL: baseUrl || "https://mservers.vercel.app/api/koa/",
    baseURL: baseUrl,
    timeout: 15000,
    headers: {
        "Content-Type": "application/json"
    }
});

// 添加请求拦截器
netfetch.interceptors.request.use((config) => {
    config.headers = {
        token: localStorage.token,
        // "X-LC-Session": leanCloudUser ? leanCloudUser.sessionToken : null,
        ...config.headers
    };
    return config;
}, () => {
    let err = {
        success: false,
        code: "9001",
        message: "请求时发生错误了",
        data: ""
    }
    return err;
});

// 添加响应拦截器
netfetch.interceptors.response.use((response) => {
    if (response.headers.token) {
        const refreshEvent = new CustomEvent('refreshToken', {
            detail: {
                type: "refresh",
                token: response.headers.token
            }
        });
        window.dispatchEvent(refreshEvent);
    }
    if (response.data.code == 403) {
        const refreshEvent = new CustomEvent('refreshToken', {
            detail: {
                type: "uncheck",
            }
        });
        window.dispatchEvent(refreshEvent);
    }
    return response.data;
}, (error) => {
    if (error.response) {
        let err = error.response;
        if (isJson(err.data)) {
            return err.data
        }
    }
    return {
        success: false,
        code: "9001",
        message: "请求错误",
        data: {}
    }
});

export default netfetch;

//判断obj是否为json对象
function isJson(obj) {
    var isjson =
        typeof obj == "object" && Object.prototype.toString.call(obj).toLowerCase() == "[object object]" && !obj.length;
    return isjson;
}